import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';
import getConfig from 'next/config';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import tinycolor from 'tinycolor2';
import * as gtag from '../lib/gtag';

import '../css/fonts.css';
import '../css/tailwind.css';
import { AppProps } from 'next/app';
import { useConfig, useIsomorphicLayoutEffect } from '../lib/hooks';
import { withRedux } from '../lib/redux';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const config = getConfig();
    const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
    Sentry.init({
        enabled: process.env.NODE_ENV === 'production',
        integrations: [
            new RewriteFrames({
                iteratee: (frame) => {
                    frame.filename = frame.filename?.replace(distDir, 'app:///_next');
                    return frame;
                }
            })
        ],
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
    });
}

function App({ Component, pageProps, err }: AppProps & { err: unknown }) {
    let config = useConfig();
    const router = useRouter();
    useIsomorphicLayoutEffect(() => {
        let colors = config.ui.colors;
        let primaryRgb = tinycolor(colors.primary).toRgb();

        document.documentElement.style.setProperty('--primary', colors.primary);
        document.documentElement.style.setProperty('--primary-25', `rgba(${primaryRgb.r}, ${primaryRgb.g}, ${primaryRgb.b}, .25)`);
        document.documentElement.style.setProperty('--primary-dark', colors.primaryDark);
        document.documentElement.style.setProperty('--primary-contrast', colors.primaryContrast);
    }, [config]);

    useEffect(() => {
        if (config.tracking.gtag.enabled && config.tracking.gtag.trackingId && process.env.NODE_ENV === 'production') {
            const handleRouteChange = (url: URL) => {
                gtag.pageview(url, config.tracking.gtag.trackingId as string);
            };
            router.events.on('routeChangeComplete', handleRouteChange);
            return () => {
                router.events.off('routeChangeComplete', handleRouteChange);
            };
        }
        return;
    }, [router.events, config]);

    // Workaround for https://github.com/vercel/next.js/issues/8592
    return <Component {...pageProps} err={err} />;
}

export default withRedux(App);
